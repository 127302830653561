import './index.css';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import React from 'react';
import ReactDOM from 'react-dom';
import Wrapper from './components/Wrapper/Wrapper';
import {createRoot} from "react-dom/client";

const Root = () => (
  <Provider store={store}>
    <Wrapper />
  </Provider>
);


createRoot(document.getElementById('root')).render(<Root />);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
