import {Col, Row} from "antd";
import {Link} from "react-router-dom";
import {ROUTE_KEYS} from "../../routes";
import React, {useEffect, useState} from "react";


export default function ListMenu() {
    const [listPermissions, setListPermissions] = useState({
            can_view_inbound: localStorage.getItem('can_view_inbound') === 'true',
            can_view_outbound: localStorage.getItem('can_view_outbound') === 'true',
        });

    // useEffect(() => {
    //     setListPermissions({
    //         can_view_inbound: localStorage.getItem('can_view_inbound'),
    //         can_view_outbound: localStorage.getItem('can_view_outbound')
    //     });
    // }, []);

    return (
        <>
            {listPermissions.can_view_inbound || listPermissions.can_view_outbound ? (
                <Row gutter={16} justify="space-between" style={{color: '#fff'}}>
                    <>
                        <Col span={8}>
                            <Link style={{color: '#fff'}} to={ROUTE_KEYS.INVENTORY}>Voorraad</Link>
                        </Col>
                        {listPermissions.can_view_inbound ? (
                        <Col span={8}>
                            <Link
                                style={{color: '#fff',}}
                                type="link"
                                to={ROUTE_KEYS.INBOUND}>Inslagen</Link>
                        </Col>) : null}
                        {listPermissions.can_view_outbound ? (
                        <Col span={8}>
                            <Link style={{color: '#fff'}} type="link"
                                  to={ROUTE_KEYS.OUTBOUND}>Uitslagen</Link>
                        </Col>) : null}
                    </>
                </Row>
            ) : null}
        </>
    )
}