import { Avatar, Button, Col, Divider, Input, Layout, Modal, PageHeader, Row, Table, Tag } from 'antd';
import { Link } from 'react-router-dom';
import {FileExcelOutlined, FilePdfOutlined, PlusOutlined} from '@ant-design/icons';
import { connect } from 'react-redux';
import { deleteEntity, fetchEntities } from '../../helpers/crud';
import { withRouter } from 'react-router';
import Column from 'antd/lib/table/Column';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { API_URL } from '../../Constants';
import xhr from '../../helpers/xhr'

const { Search } = Input;
const { Content } = Layout;
const { confirm } = Modal;

const mapState = state => ({
  permissions: state.permissions,
});

const mapDispatch = dispatch => ({});

function InboundList(props) {
  const [results, setResults] = useState();
  const [originalResults, setOriginalResults] = useState();
  const [loading, setLoading] = useState();
  const [params, setParams] = useState({limit: 50, sort: "-date"
});
  const [accessGranted, setAccessGranted] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 50,
  });

  useEffect(() => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    const fetch = async (params = {}) => {
      setLoading(true);

      // const { data } = await fetchEntities('products/grouped_by_product', params, source.token);
      const { data } = await fetchEntities('inbound-instructions', params, source.token);

      if (data && data.results?.length ) {
        setLoading(false);
        setResults(data.results);
        setOriginalResults(data.results);
        setPagination(p => {
          return { ...p, total: data.count };
        });

        return data;
      }
    };

    fetch(params);

    return () => {
      source.cancel('Operation canceled by the user.');
    };
  }, [props.permissions, accessGranted, params]);

  const doExport = (recordId) => {
    return xhr().post(`${API_URL}/generate-token/`, null, {
      headers: {
        'Authorization': `Token ${localStorage.getItem('claresse_token')}`
      }
      }).then((response) => {
        window.open(`${API_URL}/inslagen/?TOKEN=${response.data.key}&uuid=${recordId}`, '_blank');
      })
  }

  return (
    <Layout>
      <PageHeader title="Inslagen" />
      <Content
        style={{
          background: '#fff',
          padding: 24,
          margin: 0,
          minHeight: 280,
        }}
      >
        <>
          <Row>
            <Col
              lg={{
                span: 6,
              }}
              xs={24}
              style={{ marginBottom: 20 }}
            >
              <Search
                  allowClear placeholder="Zoek op referentie of notitie..."
                  onSearch={(value)=> setParams({...params, search: value })}
                  style={{ width: '100%' }} />
            </Col>
          </Row>

          <Table
            scroll={{ x: true }}
            size="middle"
            rowClassName={(record, index) => (index % 2 ? 'even' : 'odd')}
            rowKey={record => record.id}
            dataSource={results}
            pagination={pagination}
            loading={loading}
            onChange={(pagination, filters, sorter) => {
                setParams({
                    ...params,
                    page: pagination.current,
                    sort: sorter.field ? `${sorter.order === 'descend' ? '-' : ''}${sorter.field}` : null,
                });
            }}
          >
            <Column
              title="#"
              sorter={(a, b) => a.id && a.id.toString().localeCompare(b.id.toString())}
              dataIndex="id"
            />
          <Column
              title="Referentie"
              dataIndex="client_reference_number"
              sorter={(a, b) => a.client_reference_number && a.client_reference_number.localeCompare(b.client_reference_number)}
              render={(text, record) => (
                  <Link to={`/inbound/${record.uuid}`}>{text && text !== "" ? text : <em>Geen referentie</em>}</Link>
              )}
            />
            <Column
              title="Ontvangstdatum"
              sorter={(a, b) => a.date && a.date.localeCompare(b.date)}
              dataIndex="date"
              render={(text, record) => text ? moment(text).format('DD-MM-YYYY') : null}
            />
            <Column
              title="Notitie"
              dataIndex="notes"
            />
            <Column
              title="Notitie transport"
              dataIndex="notes_transport"
            />
            <Column
               title="CMR"
               dataIndex="cmr_scan"
               render={(text, record) => {
                 if (record.cmr_scan) {
                   return <a href={record.cmr_scan} target="_blank"><FilePdfOutlined/>CMR</a>
                 }
                 return null
               }}
            />
            <Column
               title="Export"
               dataIndex="document_export"
               render={(text, record) => {
                 if (record.uuid) {
                   return <Button type="link" onClick={() => doExport(record.uuid)}><FileExcelOutlined/>Export</Button>
                 }
                 return <Button type="link" disabled>Exporteren niet mogelijk</Button>
               }}
            />
          </Table>
        </>
      </Content>
    </Layout>
  );
}

export default withRouter(connect(mapState, mapDispatch)(InboundList));
