import InventoryList from './components/Inventory/InventoryList';
import InboundList from "./components/Inventory/InboundList";
import InboundDetail from "./components/Inventory/InboundDetail";
import Login from './components/Auth/Login';
import NoMatch from './components/NoMatch';
import OutboundList from "./components/Inventory/OutboundList";
import OutboundDetail from "./components/Inventory/OutboundDetail";

// file with routes
export const ROUTE_KEYS = {
    ROOT: '/',
    LOGIN: '/login',
    INVENTORY: '/voorraad',
    INBOUND: '/inbound',
    INBOUND_DETAIL: '/inbound/:uuid',
    OUTBOUND: '/outbound',
    OUTBOUND_DETAIL: '/outbound/:uuid',
};

export const PRIVATE_ROUTES = {
    INVENTORY: {
        component: InventoryList,
        exact: true,
        key: ROUTE_KEYS.INVENTORY,
        path: ROUTE_KEYS.INVENTORY,
    },
    INBOUND: {
        component: InboundList,
        exact: true,
        key: ROUTE_KEYS.INBOUND,
        path: ROUTE_KEYS.INBOUND,
    },
    INBOUND_DETAIL: {
        component: InboundDetail,
        exact: true,
        key: ROUTE_KEYS.INBOUND_DETAIL,
        path: ROUTE_KEYS.INBOUND_DETAIL,
    },
    OUTBOUND: {
        component: OutboundList,
        exact: true,
        key: ROUTE_KEYS.OUTBOUND,
        path: ROUTE_KEYS.OUTBOUND,
    },
    OUTBOUND_DETAIL: {
        component: OutboundDetail,
        exact: true,
        key: ROUTE_KEYS.OUTBOUND_DETAIL,
        path: ROUTE_KEYS.OUTBOUND_DETAIL,
    }
};

export const PUBLIC_ROUTES = {
    ROOT: {
        component: Login,
        exact: true,
        key: ROUTE_KEYS.LOGIN,
        path: ROUTE_KEYS.LOGIN,
    },
    LOGIN: {
        component: Login,
        exact: true,
        key: ROUTE_KEYS.LOGIN,
        path: ROUTE_KEYS.LOGIN,
    },
};
